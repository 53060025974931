<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="$t('dashboard.menu.channel')">
        <v-card-text>
          <v-row class="mt-4">
            <!--<v-col
              v-if="lote.length > 0"
              cols="12"
              md="2"
            >
              <v-btn
                v-if="!$store.state.app.onlyShow"
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                :disabled="!ready"
                @click="process()"
              >
                <span>{{ $t('btn.process') }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>-->
            <v-col
              v-if="lote.length > 0"
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="canceleUpload()"
              >
                <span>{{ $t('btn.canceleUpload') }}</span>
                <v-icon>{{ icons.mdiCancel }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form class="multi-col-validation">
            <v-row v-if="lote.length === 0">
              <v-col
                v-if="sourcesByUser.length > 0"
                cols="12"
                md="2"
              >
                <v-select
                  v-model="search.source"
                  :items="sourcesByUser"
                  :label="$t('dashboard.source')"
                  outlined
                  dense
                  item-text="name"
                  item-value="slug"
                  hide-details="auto"
                  @change="setSource"
                ></v-select>
              </v-col>

              <v-col
                v-if="search.source"
                cols="12"
                md="4"
              >
                <v-select
                  v-model="search.contrate_id"
                  :items="contratesBySource"
                  :label="$t('menu.contratos')"
                  outlined
                  dense
                  item-text="identificador"
                  item-value="id"
                  clearable
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('menu.contratos') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.identificador"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.identificador }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>

              <template v-if="search.source === 'solways'">
                <v-col
                  v-if="search.contrate_id"
                  cols="12"
                  md="6"
                >
                  <v-file-input
                    v-model="files"
                    accept=".json"
                    exc
                    show-size
                    outlined
                    dense
                    :label="`${$t('lbl.file')}s`"
                    hide-details
                    multiple
                    @change="setFile"
                  >
                  </v-file-input>
                </v-col>
              </template>
            </v-row>

            <v-row v-if="lote.length > 0">
              <!--<v-col
                v-for="(json, indJ) in lote"
                :key="indJ"
                cols="4"
              >
                <json-viewer
                  :value="json.content"
                  :sort="true"
                  :boxed="true"
                ></json-viewer>
              </v-col>-->
              <template v-if="arrHotelsList.length > 0">
                <FileHotel
                  v-for="(hotel, indH) in lote"
                  :key="indH"
                  :lote="hotel"
                  :hotel="hotel.hotel"
                  :pos="indH"
                  :cant="lote.length"
                  :list-hotel="arrHotelsList"
                  :hotels-pact="hotelsPactados"
                  :all-rooms="allRooms"
                  :all-plans="allPlanes"
                  :all-categories-tarifarios="allCategoriesTarifarios"
                  :all-tarifas="allTarifas"
                  @getItem="getItem"
                  @getHotels="getHotels"
                />
              </template>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>

    <!-- MOSTRAR CANCEL UPLOAD -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.cancele')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>{{ $t('msg.canceleUpload', { n: 'Solways' }) }}</v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="confirmCancele()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Loading
      v-if="isLoading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
// eslint-disable-next-line import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
} from '@mdi/js'
import FileHotel from '../utils/FileHotel.vue'

export default {
  components: {
    AppCardCode,
    Loading,
    FileHotel,
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
      },
      item: {},
      loading: false,
      permisos: localStorage.getItem('permisos_auth'),
      files: [],
      lote: [],
      json: null,
      isDialogVisibleDelete: false,
      sources: [],
      sourcesUser: [],
      contrates: [],
      search: {
        source: null,
        contrate_id: null,
      },
      user: {},
      ready: true,
      operadorsId: [],
      listHotel: [],
      allHotel: [],
      hotelsPactados: [],
      allRooms: [],
      allPlanes: [],
      allCategoriesTarifarios: [],
      allTarifas: [],
      contrate: null,
    }
  },
  computed: {
    ...mapState({
      onlyShow: state => state.app.onlyShow,
      hotelsProductListCache: state => state.app.hotelsProductListCache,
    }),
    contratesBySource() {
      let result = []
      if (this.search.source) {
        result = this.contrates.filter(e => e.source.includes(this.search.source))
      }

      return result
    },
    sourcesByUser() {
      const arr = []
      this.sources.forEach(element => {
        if (this.sourcesUser.includes(element.slug)) {
          arr.push(element)
        }
      })

      return arr
    },
    arrHotelsList() {
      const arr = []

      this.allHotel.forEach(elementHot => {
        if (this.contrate.operadorsId.includes(elementHot.operador_id)) {
          arr.push(elementHot)

          // this.listHotel.push(elementHot)
        }
      })

      return arr
    },
  },
  created() {
    this.profile()
    this.getItem()
    this.fetchDataFromEndpoints()
  },
  methods: {
    ...mapMutations(['setOnlyShow']),
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
    },
    async getItem() {
      await this.axios
        .post('channel-solways/get-file', { user_id: this.user.id }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.lote = res.data.data.data
          this.contrate = res.data.data.contrate
          if (this.contrate) {
            this.hotelsPactados = this.contrate.hotels_pactados
            this.allTarifas = res.data.data.tarifas
          }
          this.lote.forEach(element => {
            if (!element.listo) {
              this.ready = element.listo
            }
          })

          /* if (this.lote) {
            if (this.lote.content) {
              this.json = this.lote.content
            }
          } */

          this.sourcesUser = res.data.data.sources
        })
        .finally(() => this.resetFile())
    },
    async fetchDataFromEndpoints() {
      /* const jsonContrate = {
        source: 'solways',
      } */
      const slugs = []
      slugs.push('hotetecapi')
      slugs.push('dingusapi')
      slugs.push('solwaysapi')
      try {
        const [
          resHotels,
          resContrates,
          resSources,
          resRooms,
          resPlans,

          // resCateTarifarios,
        ] = await Promise.all([
          this.axios
            .post(
              'hotels/faster',
              { fields: ['name', 'direccion', 'operador_id', 'rooms_id'] },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              },
            ),
          this.axios
            .post('contrate_hotels/show-list-channel', { all: true }, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post(
              'api_keys/verify-status-by-slugs',
              { slugs },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              },
            ),
          this.axios
            .get(`nom_habitacion_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_regimen_alimenticio?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),

          /* this.axios
            .get('nom_categories_tarifarios_hotels?per_page=1000', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }), */
        ])

        resHotels.data.data.forEach(element => {
          this.allHotel.push(element)
        })

        if (resContrates.data.success) {
          this.contrates = resContrates.data.data.data
        }

        if (resSources.data.success) {
          resSources.data.data.forEach(element => {
            if (element.source === 'hotetec') {
              this.nameHotetec = element.api_name
            } else if (element.source === 'dingus') {
              this.nameDingus = element.api_name
            } else if (element.source === 'solways') {
              this.nameSolways = element.api_name
            }
            this.sources.push({
              name: element.api_name,
              slug: element.source,
            })
          })
        }

        this.allRooms = resRooms.data.data
        this.allPlanes = resPlans.data.data

        // this.allCategoriesTarifarios = resCateTarifarios.data.data
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
    async getHotels() {
      try {
        const [
          resHotels,
        ] = await Promise.all([
          this.axios
            .post(
              'hotels/faster',
              { fields: ['name', 'direccion', 'operador_id', 'rooms_id'] },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                },
              },
            ),
        ])

        this.allHotel = []
        resHotels.data.data.forEach(element => {
          this.allHotel.push(element)
        })
      } catch (err) {
        console.error('Error fetching data:', err)
      }
    },
    setSource() {
      if (this.contratesBySource.length > 0) {
        if (this.contratesBySource.length === 1) {
          // eslint-disable-next-line prefer-destructuring
          this.search.contrate_id = this.contratesBySource[0].id
        }
      }
    },

    async setFile() {
      if (this.files.length > 0) {
        this.isLoading = true
        const formData = new FormData()
        this.files.forEach(element => {
          formData.append('files[]', element)
        })
        formData.append('data', JSON.stringify(this.search))

        await this.axios
          .post('channel-solways/put-file', formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
        // eslint-disable-next-line no-unused-vars
          .then(response => {
            if (response.data.success) {
              this.getItem()
            }
          })
        // eslint-disable-next-line no-return-assign
          .finally(() => this.resetFile())
      }
    },
    canceleUpload() {
      this.isDialogVisibleDelete = true
    },
    async confirmCancele() {
      this.isLoading = true
      const json = {
        user_id: this.user.id,
      }
      await this.axios
        .post('channel-solways/delete-file', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === true) {
            this.search.source = null
            this.search.contrate_id = null
            this.json = null
            this.getItem()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.resetFile())
    },
    async process() {
      this.isLoading = true
      const json = {
        id: this.lote.id,
      }
      await this.axios
        .post('channel-solways/process-file', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        // eslint-disable-next-line no-unused-vars
        .then(res => {
          /* if (res.data.success === true) {
            this.search.source = null
            this.search.contrate_id = null
            this.json = null
            this.getItem()
            this.$toast.success(this.$t('msg.infoSuccess'))
          } */
        })
        .finally(() => this.resetFile())
    },

    resetFile() {
      this.files = []
      this.isDialogVisibleDelete = false
      this.isLoading = false
    },
  },
}
</script>
