<template>
  <v-row>
    <v-col cols="12">
      <Simulador />
    </v-col>
  </v-row>
</template>

<script>
import Simulador from './form/Simulador.vue'

export default {
  components: {
    Simulador,
  },
}
</script>
